import React from 'react';
import { Link } from 'gatsby';
import css from '@emotion/css';

import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import { COLORS, BREAKPOINTS } from '../../../ui/variables';
import CitiesMap from '../../../components/locations/CitiesMap';
import Heading1 from '../../../ui/Heading1';
import { ComingSoon } from '..';
import { Uppercase } from '../../../ui/TypographyStyles';
import {
  Content,
  Left,
  HeadingWrap,
  Heading,
  Right,
  LocationList,
  Location,
  ArrowRight,
  LinkTo,
  LocationName,
} from '../washington-dc';
import ParagraphSmall from '../../../ui/ParagraphSmall';
import { breadcrumbBoston } from '../../../data/seo';

export const locations = [
  {
    name: 'Newton Centre',
    isComingSoon: false,
    address: '718 Beacon St, Newton, MA 02459',
    path: '/locations/boston/newton-centre',
    position: {
      lat: 42.33069784925326,
      lng: -71.19005990215237,
    },
  },
  {
    name: 'Brookline',
    isComingSoon: false,
    address: '297 Harvard St, Brookline, MA 02446',
    path: '/locations/boston/brookline',
    position: {
      lat: 42.343186573039176,
      lng: -71.1221525733167,
    },
  },
];

const BostonLocationPage = () => {
  const locationListWrap = (
    <LocationList>
      {locations.map(location => (
        <Location>
          <LinkTo to={location.path}>
            <div
              css={css`
                @media ${BREAKPOINTS.MOBILE} {
                  margin-top: ${location.isComingSoon ? '34px' : 'inherit'};
                }
              `}
            >
              <LocationName>{location.name}</LocationName>
              <ParagraphSmall
                css={css`
                  margin: 0;
                `}
              >
                {location.address}
              </ParagraphSmall>
            </div>
            <div
              css={css`
                height: fit-content;
              `}
            >
              {location.isComingSoon && (
                <ComingSoon
                  css={css`
                    margin-bottom: 0;
                    margin-right: 20px;
                    @media ${BREAKPOINTS.MOBILE} {
                      position: absolute;
                      top: 24px;
                      left: 0;
                    }
                  `}
                />
              )}
              <ArrowRight />
            </div>
          </LinkTo>
        </Location>
      ))}
    </LocationList>
  );

  return (
    <Layout>
      <SEO
        title='Modern Boston Vet Practices | Small Door Veterinary'
        description='Our state-of-the-art Boston veterinary practices are modern, stress-free environments, conveniently located near where you live, across the city.'
        breadcrumbSchema={breadcrumbBoston}
      />
      <HeadingWrap>
        <Heading>
          <Uppercase
            css={css`
              color: ${COLORS.ORANGE};
              margin: 0 auto;
              width: fit-content;
            `}
          >
            <Link to='/locations'>Locations</Link>
          </Uppercase>
          <Heading1
            css={css`
              margin-top: 24px;
            `}
          >
            Our Boston Veterinary Practices
          </Heading1>
        </Heading>
      </HeadingWrap>

      <Content>
        <Left>
          <div
            css={css`
              width: 100%;
            `}
          >
            {locationListWrap}
          </div>
        </Left>

        <Right>
          <CitiesMap id='desktop-map' locations={locations} />
        </Right>
      </Content>
    </Layout>
  );
};

export default BostonLocationPage;
